/* Reset
-------------------------------------------------- */
*, *:before, *:after {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

article, aside, details, figcaption, figure, hgroup, main, section, summary {
  display: block;
}
