/* Imports
---------------------------------------------------*/

@import "import/mixin";

$font-path: "../fonts/";

/* customize modules */
@import "import/fonts";
@import "import/reset";


$text-color: #333;
$link-color: #777;
$white: #fff;

$container-size: 1500px;
$content-size: 1000px;

/* Basics
-------------------------------------------------- */
html {
  font-family: "Cardo", serif;
  font-size: 1.25em;
  line-height: 1.5em;
  color: $text-color;
}

body {
  margin: 0 auto;
  background: $white;
}

img {
  display: block;
  max-width: 100%;
}

a, main a {
  color: $link-color;
  text-decoration: none;
}

a:hover {
 color: $text-color;
 text-decoration: underline;
}

ul {
  list-style: none;
}

em {
  font-weight: 400;
  font-style: italic;
}

header, nav {
  font-size: 1.2em;
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
  line-height: 1.23em;
  letter-spacing: 1px;
  text-align:center;
  overflow: hidden;
  padding-top:1em;
  padding-bottom:1em;

  & > * {
    display: inline-block;
    margin:0 0.25em;
  }

  strong {
    font-weight: 700;
  }

  a {
    color: $text-color;
  }
}

body.home:not([class*="tag"]) {
  header {
    position: absolute;
    z-index: 1000;
    width: 100%;
  }
}

nav {
  ul {
    li {
      display: inline-block;
      margin:0 0.25em;

      a.active {
        text-decoration: underline;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  header, nav {
    font-size: 1em;
  }
}

main {
  max-width: $container-size;
  margin: 3em auto;
  padding: 0 1em;
}

footer {
  position: relative;
  z-index: 200;
  background-color: darken(white, 5%);

  body[style*="background-color"] & {
    background-color: inherit !important;
  }

  .footer {
    font-size:.8em;
    max-width: $container-size;
    margin: 0 auto;
    padding: 0.5em 1em 3em;

    p {
      margin: 1em 0;
    }

    a {
      color: $text-color;
      display: block;
    }
  }
}

html.showmenu {
  footer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;

    .footer {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .top {
    z-index: 201;

    .close {
      display: block;
    }
    .home {
      display: none;
    }
  }
}

/* Sticky footer */
@media all and (min-width: 961px) {
  .wrapper {
    min-height: calc(100vh - 200px);
  }

  footer {
    height: 180px;
    overflow: hidden;
  }
}



/* Site
-------------------------------------------------- */
.serif {
  font-family: "Cardo", serif;
}

.sans-serif {
  font-family: 'Work Sans', sans-serif;
  font-weight: 300;
}

.monospaced {
  font-family: 'Courier New', Courier, serif;
  font-size: 0.9em;
  line-height: 1.4em;
}

.text-center {
  text-align: center;
}

/* Headings
-------------------------------------------------- */
h1 {
  font-family: "Work Sans", sans-serif;
  text-align: left;
  font-size: 1.5em;
  line-height: 1.23em;
  font-weight: 300;
  margin-bottom: .2em;
  letter-spacing: 1px;
  padding-bottom: 20px;
}

h2 {
  font-family: "Work Sans", sans-serif;
  font-size: 0.8em;
  line-height: 1.25em;
  font-weight: 300;
  margin-bottom: .5em;
  color: $text-color;
  padding-top: 45px;
}

h3 {
  font-size: 1.4em;
  line-height: 1.5em;
  font-weight: 400;
  margin-bottom: .5em;
  color: $text-color;
}

h4 {
  font-size: 1.1em;
  font-weight: 400;
  line-height: 1.33em;
  margin-top: 0.7em;
  margin-bottom: .5em;
  color: $text-color;
}

h2 a, h3 a, h4 a {
  color: inherit;
}

@media screen and (max-width: 1150px)  {
 h4 {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.25em;
    margin-top: 0.6em;
    margin-bottom: 0.5em;
  }
}


/* Main
-------------------------------------------------- */
.main {
  padding-bottom: 1.5em;
}

.main hr {
  margin: 3em 0;
  height: 2px;
  background: $white;
}

.main p,
.main figure,
.main ul,
.main ol {
  margin-bottom: 1.5em;
}

/* Content blocks */
.content {
  max-width: $content-size;
  margin: 0 auto;

  .headline {
    margin-top: 5rem;
    margin-bottom: 2rem;
  }

  .text-big {
    font-size: 1.6em;
    line-height: 1.4em;
  }
}

/* Text blocks */
.text ul,
.text ol {
  margin-left: 1em;
}

.tag {
  font-family: "Work Sans", sans-serif;
  font-size: 0.65em;
  line-height: 1.1em;
  font-weight: 300;
  margin-bottom: 1em;
  color: $text-color;
}

@media screen and (max-width:541px) {
  .main figure {
    margin-bottom: .75em;
  }

  .main {
    padding-bottom: 0.75em;
  }

  .main {
    padding: 1em;
  }
}


/* Projekt
-------------------------------------------------- */
.project {
  .info {
    text-align: center;
    vertical-align: center;
    padding: 15%;
    margin-bottom: 1.5rem;
    font-size: 1.3em;
    line-height: 1.35em;
    color: $text-color;
    
    p {
      margin-bottom: 1.5em;
    }
  }
}

@media screen and (max-width: 850px) {
  .project {
    .info {
      padding: 5%;
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 450px) {
  .project {
    .info {
      padding: 5%;
    }
  }
}

@media screen and (min-width: 1700px) {
  .project {
    .info {
      font-size: 1.6em;
      line-height: 1.4em;
      padding: 20%;
    }
  }
}


/*  Mehr Projekte
------------------------------------------*/
.more-projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
  margin-top: 3em;

  & > div {
    width: 22.75%;
    box-sizing: border-box;
    margin-left: 0;
    margin-bottom: 3em;
  }
}


@media all and (max-width: 700px) {
  .more-projects {
    & > div {
      width: 48%;
      margin-bottom: 2em;
    }
  }
}

@media all and (max-width: 350px) {
  .more-projects {
    h4 {
      font-size: 0.66em;
    }
  }
}

/* Back To Top
----------------------------------*/
.top {
  display: none;
  position: fixed;
  z-index: 10;
  bottom: 1em;
  right: 2em;
  width: 50px;

  img, a {
    outline: none;
    border: 0;
  }

  .close {
    display: none;
  }

  .home {
    display: block;
  }
}

/* Slideshow (Start)
-----------------------------*/
.hero {
  position: relative;
  top: 0; right: 0;
  width: 100%;
  height: 100%;
  height: 100vh;
  overflow: hidden;

  .slideshow {
    width: 100vw;
    height:100vh;
    min-width: 100%;
    min-height:100%;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      height:100%;
      min-height:340px;
      min-width:100%;
    }
  }

  .background-slide {
    width: 0px;
    height: 0px;
    padding-top: 50vh;
    padding-bottom: 50vh;
    padding-left: 100vw;
    overflow: hidden;
    background: center center no-repeat;
    background-size: cover;
  }

  .down {
    position: absolute;
    z-index: 2000;
    width: 35px;
    top: 1.5em;
    right: 1em;
  }
}


/* Grid
-----------------------------*/
.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;

  .grid-half {
    width: 48%;
    margin-left: 0;
    margin-bottom: 3em;
  }

  .grid-one-third {
    width: 30%;
  }

  .grid-quarter {
    width: 23%;
  }

  a {
    display: block;
  }
}

@media all and (max-width: 960px) {
  .grid {
    .grid-half,
    .grid-one-third,
    .grid-quarter {
      width: 48%;
    }
  }
}

@media all and (max-width: 540px) {
  .grid {
    .grid-half,
    .grid-one-third,
    .grid-quarter {
      width: 100%;
    }
  }
}
